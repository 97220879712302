.tag-input {
  --border-color: #ccc;
  --radius: 4px;
  --focus: #86b7fe;
  --focus-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0.25);
  --tag-background: #888888;

  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  padding: 4px 8px
}
.tag-input:focus-within {
    border-color: var(--focus);
    outline: 0;
    box-shadow: var(--focus-shadow);
  }
.tag-input .tag {
    background-color: var(--tag-background);
    color: white;
    margin-right: 2px;
    padding: 2px 8px;
    text-align: center;
    border-radius: var(--radius)
  }
.tag-input .tag .remove-tag-button {
      margin-left: 4px;
      cursor: pointer;
      color: white;
      background: none;
      border: none;
    }
.tag-input .new-tag {
    background: none;
    border: none
  }
.tag-input .new-tag:focus {
      outline: none;
    }
